import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { GridProjects, Hero, HeroFilter, Page, SectionMedia } from "components";
import { getCategoryFilters } from "utils";

const PageProjects = ({
  data: {
    config: { header, hero, seo },
    projectCategories,
    projects,
  },
  location,
}) => {
  const filterButtons = getCategoryFilters(projectCategories, "/projects", {
    label: "All Projects",
  });

  const seoOptions = {
    ...seo,
    openGraph: {
      url: location.href,
      image: `${location.origin}/logo.png`,
    },
  };

  const pageOptions = {
    header: {
      color: "secondary",
    },
  };

  const headerOptions = {
    bgcolor: "primary.main",
    buttons: filterButtons,
    className: "primary",
    color: "common.white",
    ...header,
  };

  return (
    <Page options={pageOptions} seo={seoOptions}>
      <HeroFilter {...headerOptions} />
      {projects && (
        <SectionMedia>
          <GridProjects projects={projects} />
        </SectionMedia>
      )}
      <Hero {...hero} />
    </Page>
  );
};

PageProjects.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      hero: PropTypes.shape({
        buttons: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
        context: PropTypes.string,
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        postType: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    projectCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
        })
      ),
    }),
    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              category: PropTypes.string,
              date: PropTypes.string,
              description: PropTypes.string,
              image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
              }),
              title: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const projectsPageQuery = graphql`
  query ProjectsPageQuery {
    config: pagesYaml(id: { eq: "PageProjects" }) {
      header {
        heading
      }
      hero {
        buttons {
          label
          path
        }
        context
        heading
      }
      id
      seo {
        postType
        title
      }
    }
    projectCategories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/projects/" }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        category: fieldValue
      }
    }
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/data/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            date(formatString: "MMM. DD, YYYY")
            description
            image {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 480, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
          }
          id
        }
      }
    }
  }
`;

export default PageProjects;
